import React, { useEffect } from "react";
import { useNavigate, Outlet, useMatch } from "react-router";
import { DeviceHub, Hub } from "@mui/icons-material";

const Stats = () => {
  const navigate = useNavigate();

  // Match paths explicitly
  const isNodeTab = useMatch("/stats/node/*");
  const isNetworkTab = useMatch("/stats/network");

  useEffect(() => {
    if (!isNodeTab && !isNetworkTab) navigate("/stats/node");
  }, []);

  // Determine selected tab based on URL
  const selectedTab = isNetworkTab ? "network" : "node";

  // Function to navigate to a new tab
  const updateTab = (tab) => {
    navigate(`/stats/${tab}`);
  };

  return (
    <>
      <div className={`sub-tabs`}>
        <button
          style={{ borderTopLeftRadius: "8px" }}
          className={`tab sub-tab-updated ${
            selectedTab === "node" ? "active tab-active-updated" : ""
          }`}
          onClick={() => updateTab("node")}
        >
          <DeviceHub className="icon" />
          Node
        </button>

        <button
          style={{ borderTopRightRadius: "8px" }}
          className={`tab sub-tab-updated ${
            selectedTab === "network" ? "active tab-active-updated" : ""
          }`}
          onClick={() => updateTab("network")}
        >
          <Hub className="icon" />
          Network
        </button>
      </div>

      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Stats;
