import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router";
// import { ethers } from "ethers";
import { ArrowLeft } from "lucide-react"; // Import ArrowLeft icon
// import NodeReputationABI from "../abi/NodeReputation.json";
// import { PUBLIC_RPC_URL, NODEREPUTATION_ADDRESS } from "../lib/config";
import Loader from "./Loader";
import ComparisonChart from "../components/ComparisonChart";
import TimeIntervalTable from "../components/TimeIntervalTable";
import PingTimeStampChart from "../components/PingHeatmapChart";
import { NODE_PING_GRACE } from "../lib/config";

const MetricsAnalytics = ({
  allTimestamps,
  successTimestamps,
  pingTimestamps,
  pingThreshold,
  error,
}) => {
  const { lookupAddress } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const metric = searchParams.get("metric");
  const [successRates, setSuccessRates] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [pingChartData, setPingChartData] = useState([]);
  const [successCounts, setSuccessCounts] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleBack = () => {
    searchParams.delete("metric");
    setSearchParams(searchParams);
  };

  const getTimeSlot = (date, totalParts) => {
    const hour = date.getHours();
    const minute = date.getMinutes();

    // Calculate the duration of each part (in minutes)
    const partDuration = 60 / totalParts;

    // Determine which part the minute falls into
    const partIndex = Math.floor(minute / partDuration);

    // Calculate the start and end of that part
    const startMinute = partIndex * partDuration;
    const endMinute = startMinute + partDuration;

    return {
      hour,
      range: `${startMinute}-${endMinute}`,
      partIndex: partIndex,
    };
  };

  const groupTimestampsByBlocks = (timestamps, n) => {
    const grouped = {};

    timestamps.sort().forEach((ts) => {
      const date = new Date(ts);
      const dateKey = date.toISOString().slice(2, 10); // "yy-mm-dd"
      const hour = String(date.getHours()).padStart(2, "0"); // Ensures "hh" format
      const key = `${dateKey} ${hour}:00`; // Final format "yy-mm-dd hh:00"

      if (!grouped[key]) grouped[key] = [];
      grouped[key].push({
        date: dateKey,
        hour,
        minute: date.getMinutes(),
        original: ts,
      });
    });

    const sortedKeys = Object.entries(grouped)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Ensures proper date-hour order
      .map(([key, value]) => ({ key, value })); // Convert back to array of objects

    const result = sortedKeys.map(({ key, value: tsList }, itr) => {
      const blockSize = Math.ceil(tsList.length / n); // Ensure distribution into `n` blocks
      const name = key;
      const data = Array(n).fill(0);

      for (let i = 0; i < tsList.length; i += blockSize) {
        const index = getTimeSlot(new Date(tsList[i].original), n).partIndex;
        data[index] = 1;
      }

      // Mark gaps before and after actual data
      if (itr === 0) {
        const firstIndex = data.indexOf(1);
        for (let index = 0; index < firstIndex; index++) {
          data[index] = 2; // Mark preceding slots as `2`
        }
      }

      if (itr === sortedKeys.length - 1) {
        const lastIndex = data.lastIndexOf(1);
        if (lastIndex !== -1) {
          for (let index = lastIndex + 1; index < data.length; index++) {
            data[index] = 2; // Mark trailing slots as `2`
          }
        }
      }

      return { name, data };
    });

    return result;
  };

  const generatePingChartData = (timestamps, interval) => {
    // Early return for empty requests
    if (!timestamps.length) return [];

    // Sort requests in ascending order
    timestamps.sort((a, b) => a - b);

    const minTime = timestamps[0];
    const maxTime = timestamps[timestamps.length - 1];
    const result = [];

    // Use a sliding window approach with a request pointer
    let timestampIndex = 0;

    for (
      let currentTime = minTime;
      currentTime <= maxTime;
      currentTime += interval
    ) {
      const intervalEnd = currentTime + interval;

      // Skip requests that are before the current interval
      while (
        timestampIndex < timestamps.length &&
        timestamps[timestampIndex] < currentTime
      ) {
        timestampIndex++;
      }

      // Check if any request falls within the current window
      const found =
        timestampIndex < timestamps.length &&
        timestamps[timestampIndex] <= intervalEnd;

      result.push({
        x: new Date(
          found ? timestamps[timestampIndex] * 1000 : currentTime * 1000
        ),
        y: 1,
        backgroundColor: found ? "green" : "red",
      });
    }
    return result;
  };

  useEffect(() => {
    if (pingTimestamps && pingTimestamps.length > 0) {
      const timestamps = [...pingTimestamps].map((ts) => Number(ts));
      const pingThresholdInSeconds = Number(pingThreshold) * NODE_PING_GRACE;
      const chartData = generatePingChartData(
        timestamps,
        pingThresholdInSeconds
      );
      const blockSize = Math.floor(3600 / pingThresholdInSeconds);
      const pingChartData = groupTimestampsByBlocks(
        chartData.filter((a) => a.backgroundColor === "green").map((a) => a.x),
        blockSize
      );
      setPingChartData(pingChartData.reverse());
      setChartData(chartData);
      if (chartData) setLoading(false);
    }
  }, [pingTimestamps]); // Runs when pingTimestamps changes
  useEffect(() => {
    if (allTimestamps?.length) {
      // Convert BigInt timestamps to Number for sorting
      const sortedTimestamps = [...allTimestamps]
        .map((ts) => Number(ts))
        .sort((a, b) => a - b);

      //   const intervalInSeconds = 3600; // 1 hour in seconds
      const startTime = Math.min(...sortedTimestamps);
      const endTime = Math.max(...sortedTimestamps);

      // Calculate the total time range in seconds
      const timeRange = endTime - startTime;
      // Set the number of intervals you want
      const numIntervals = 24; // For example, divide the range into 24 intervals (24 hours)
      // Calculate the interval length (in seconds)
      const intervalInSeconds = Math.floor(timeRange / numIntervals);

      // Derive intervals from the timestamps
      const intervals = [];
      if (intervalInSeconds) {
        for (let i = startTime; i <= endTime; i += intervalInSeconds) {
          intervals.push(i);
        }
      } else {
        intervals.push(startTime);
      }

      setIntervals(intervals);

      // Calculate success rate for each interval
      const successRates = intervals.map((intervalStart) => {
        // Find the timestamps within this interval
        const intervalEnd = intervalStart + intervalInSeconds;
        const totalInInterval = sortedTimestamps.filter(
          (timestamp) =>
            timestamp >= intervalStart &&
            (timestamp < intervalEnd || intervalInSeconds === 0)
        ).length;
        const successInInterval = successTimestamps.filter(
          (timestamp) =>
            timestamp >= intervalStart &&
            (timestamp < intervalEnd || intervalInSeconds === 0)
        ).length;

        // Calculate success rate as a percentage
        return totalInInterval > 0
          ? (successInInterval / totalInInterval) * 100
          : 0;
      });
      setSuccessRates(successRates);

      const successCounts = intervals.map((intervalStart) => {
        const intervalEnd = intervalStart + intervalInSeconds;
        const totalInInterval = sortedTimestamps.filter(
          (timestamp) =>
            timestamp >= intervalStart &&
            (timestamp < intervalEnd || intervalInSeconds === 0)
        ).length;
        const successInInterval = successTimestamps.filter(
          (timestamp) =>
            timestamp >= intervalStart &&
            (timestamp < intervalEnd || intervalInSeconds === 0)
        ).length;

        return {
          total: totalInInterval,
          success: successInInterval,
          failure: totalInInterval - successInInterval,
        };
      });

      setSuccessCounts(successCounts);
      if (allTimestamps) {
        setLoading(false);
      }
    }
  }, [allTimestamps, successTimestamps]);
  useEffect(() => {
    if(!allTimestamps?.length && !pingTimestamps?.length) {
      setLoading(false);
    }
  }, [allTimestamps, successTimestamps]);
  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="refresh-section" style={{ borderTopLeftRadius: "0" }}>
        <div className="header-container">
          <div className="back-and-metrics">
            <div title="Go Back">
              <ArrowLeft
                onClick={handleBack}
                className="back-arrow"
                size={24}
              />
            </div>
            <p>
              Showing <span style={{ fontWeight: "bold" }}>{metric}</span>{" "}
              Metric Analytics for : &nbsp;
              <a
                href={`https://sepolia.arbiscan.io/address/${lookupAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lookupAddress}
              </a>
            </p>
          </div>
          {metric !== "Ping" && (
            <div className="count-display">
              <p>
                Total Attempt : <span>{allTimestamps.length}</span>
              </p>
              <p>
                Total Success: <span>{successTimestamps.length}</span>
              </p>
              <p>
                Failure:{" "}
                <span>{allTimestamps.length - successTimestamps.length}</span>
              </p>
            </div>
          )}
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}

      {!allTimestamps.length && !pingTimestamps.length ? (
        <div className="no-data-card">
          <div className="no-data-text">{"No Data Available"}</div>
        </div>
      ) : (
        <div>
          {/* Show ComparisonChart only when allTimestamps are available */}
          {allTimestamps.length > 0 && metric !== "Ping" && (
            <>
              <ComparisonChart
                successCounts={successCounts}
                intervals={intervals}
              />
              <TimeIntervalTable
                successCounts={successCounts}
                successRates={successRates}
                intervals={intervals}
              />
            </>
          )}

          {/* Show PingChart only when pingTimestamps are available */}
          {pingChartData.length > 0 && metric === "Ping" && (
            <PingTimeStampChart
              series={pingChartData}
              interval={pingThreshold}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MetricsAnalytics;
