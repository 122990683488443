import React, { useState, useEffect } from "react";
import { ethers, isAddress } from "ethers";
import { useAccount } from "wagmi";
import { Tooltip } from "react-tooltip";
import { Activity, X, ArrowUpRight, ArrowDownRight, Eye } from "lucide-react";
import NodeStatsABI from "../abi/NodeStats.json";
import NodeReputationABI from "../abi/NodeReputation.json";
import NodePoolABI from "../abi/NodePool.json";
import {
  PUBLIC_RPC_URL,
  NODESTATS_ADDRESS,
  NODEREPUTATION_ADDRESS,
  NODEPOOL_ADDRESS,
} from "../lib/config";
import { useParams, useNavigate, Link, useSearchParams } from "react-router";
import Loader from "./Loader";
import MetricsAnalytics from "./MetricsAnalytics";

const NodeStats = () => {
  const { address } = useAccount();
  const [nodeStatsContract, setNodeStatsContract] = useState(null);
  const [nodeReputationContract, setNodeReputationContract] = useState(null);
  const [nodeStats, setNodeStats] = useState(null);
  const [nodePoolContract, setNodePoolContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { lookupAddress } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState(lookupAddress || "");
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
  const [searchParams, setSearchParams] = useSearchParams();
  const [metric, setMetric] = useState("");
  const [allTimestamps, setAllTimestamps] = useState([]);
  const [successTimestamps, setSuccessTimestamps] = useState([]);
  const [pingTimestamps, setPingTimestamps] = useState([]);
  const [pingThreshold, setPingThreshold] = useState(null);

  useEffect(() => {
    const metric = searchParams.get("metric");
    setMetric(metric);
    setLoading(false);
  }, [searchParams]);

  useEffect(() => {
    if (!lookupAddress) {
      const before = sessionStorage.getItem("node");
      if (before && ethers.isAddress(before)) {
        navigate(`/stats/node/${before}`);
      }
    } else sessionStorage.setItem("node", lookupAddress);
  }, [lookupAddress]);

  const handleAddressLookup = () => {
    if (!query) {
      // Prevent navigation and display an error if the lookup field is empty
      setError("Please enter an Ethereum address to look up.");
      return;
    }

    if (!ethers.isAddress(query)) {
      setError("Invalid Ethereum address. Please enter a valid address.");
      return;
    }

    setError("");
    sessionStorage.setItem("node", query);
    navigate("/stats/node/" + query);
  };

  useEffect(() => {
    if (!lookupAddress && address) {
      navigate("/stats/node/" + address);
    }
  }, [lookupAddress, address]);

  useEffect(() => {
    if (!lookupAddress || !nodeStatsContract) return;

    if (!ethers.isAddress(lookupAddress)) {
      setError("Invalid Ethereum address");
      return;
    }

    setLoading(true);
    setError("");

    const nodeHash = ethers.keccak256(ethers.getAddress(lookupAddress));
    nodeStatsContract
      .getActivity(nodeHash)
      .then(setNodeStats)
      .catch((error) => {
        console.error("Error looking up address:", error);
        setError(`Failed to lookup address: ${error.message}`);
        setNodeStats(null);
      })
      .finally(() => {
        setLoading(false);
      });

    // Example
    async function exampleFetch() {
      // state = reequest, create, prepare, start, precommit, commit, end
      /* const temp = await nodeReputationContract.getAllTimestamps(
        nodeHash,
        "precommit"
      );
      console.log(temp);
      const temp2 = await nodeReputationContract.getSuccessTimestamps(
        nodeHash,
        "precommit"
      );
      console.log(temp2);

      console.log("node stats - getNodePingThreshold");
      const temp4 = await nodeStatsContract.getGlobalPingThreshold();
      console.log(temp4);

      console.log("node stats - ping data");
      const temp3 = await nodeStatsContract.getNodePingTimestamps(nodeHash);
      console.log(temp3);*/

      function decodeNodeEntries(result) {
        return result.map((entry) => {
          // Convert hex string to bytes
          const hexString = entry.startsWith("0x") ? entry.slice(2) : entry;
          const bytes = new Uint8Array(
            hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
          );

          // Get nodeId (first 32 bytes)
          const nodeId = Array.from(bytes.slice(0, 32))
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");

          // Extract nodeAddress (20 bytes after skipping first comma at index 32)
          const addressStart = 33; // Skip comma
          const addressEnd = addressStart + 20;
          const nodeAddressBytes = bytes.slice(addressStart, addressEnd);

          // Convert bytes to Ethereum address format (0x-prefixed)
          const nodeAddress =
            "0x" +
            Array.from(nodeAddressBytes)
              .map((b) => b.toString(16).padStart(2, "0"))
              .join("");

          // Convert remaining bytes to string
          const decoder = new TextDecoder();
          const rest = decoder.decode(bytes.slice(addressEnd));

          const [_, addedTs, reservedTs, releasedTs, status] = rest.split(",");

          return {
            nodeId: nodeId,
            nodeAddress: nodeAddress,
            addedTimestamp: parseInt(addedTs),
            reservedTimestamp: parseInt(reservedTs),
            releasedTimestamp: parseInt(releasedTs),
            status: status.replace(/'/g, "").trim(),
          };
        });
      }

      console.log("node pool");
      const temp5 = await nodePoolContract.getAllNodeEntries();
      //console.log(temp5);
      console.log(decodeNodeEntries(temp5));
    }

    exampleFetch();
  }, [lookupAddress, nodeStatsContract]);

  const fetchTimeStampData = async (metric) => {
    const nodeHash = ethers.keccak256(ethers.getAddress(lookupAddress));

    setError("");

    try {
      if (metric === "ping") {
        const pingTimestamps = await nodeStatsContract.getNodePingTimestamps(
          nodeHash
        );
        const pingThreshold = await nodeStatsContract.getGlobalPingThreshold();
        setPingThreshold(pingThreshold);
        setPingTimestamps(pingTimestamps);
        return pingTimestamps;
      } else {
        const allTimestamps = await nodeReputationContract.getAllTimestamps(
          nodeHash,
          metric
        );

        const successTimestamps =
          await nodeReputationContract.getSuccessTimestamps(nodeHash, metric);

        setAllTimestamps(allTimestamps);
        setSuccessTimestamps(successTimestamps);
        return { allTimestamps, successTimestamps };
      }
    } catch (error) {
      console.error("Error Fetching Timestamps Data:", error);
      setError(`Error Fetching Timestamps Data: ${error.message}`);
    }
  };

  useEffect(() => {
    const setTimestamps = async () => {
      setLoading(true);
      if (metric === "Ping") {
        const pingTimestamps = await fetchTimeStampData(
          metric.toLocaleLowerCase()
        );
      } else {
        const { allTimestamps, successTimestamps } = await fetchTimeStampData(
          metric.toLocaleLowerCase()
        );
      }

      setLoading(false);
    };
    if (metric) {
      setTimestamps();
    }
  }, [nodeReputationContract]);

  const organizeStats = (nodeStats) => {
    const formatDisplayLabel = (prefix) => {
      if (prefix === "globalPing") {
        return "Global Ping";
      }
      return prefix.charAt(0).toUpperCase() + prefix.slice(1);
    };

    const prefixes = [
      "Request",
      "Create",
      "Prepare",
      "Start",
      "Precommit",
      "Commit",
      "End",
      "Correctness",
      "Ping",
      "globalPing",
    ];
    const organizedStats = prefixes.map((prefix) => {
      const getPropertyKey = (prefix, suffix) => {
        if (prefix === "globalPing") {
          return `globalPing${suffix}`;
        }
        return `${prefix.toLowerCase()}${suffix}`;
      };

      const pointKey = getPropertyKey(prefix, "Point");
      const counterKey = getPropertyKey(prefix, "Counter");

      const pointValue = nodeStats[pointKey];
      const counterValue = nodeStats[counterKey];

      return {
        label: formatDisplayLabel(prefix),
        point: pointValue ? pointValue.toString() : "0",
        counter: counterValue ? counterValue.toString() : "0",
        successRate: calculateSuccessRate(pointValue, counterValue),
      };
    });

    return organizedStats;
  };

  const calculateSuccessRate = (point, counter) => {
    let numericPoint = typeof point === "bigint" ? Number(point) : point;
    let numericCounter =
      typeof counter === "bigint" ? Number(counter) : counter;

    if (
      numericCounter === 0 ||
      numericCounter === "0" ||
      isNaN(numericPoint / numericCounter)
    )
      return null;
    return (numericPoint / numericCounter) * 100;
  };

  useEffect(() => {
    const init = async () => {
      try {
        // Use public client when wallet is not connected
        const provider = new ethers.JsonRpcProvider(PUBLIC_RPC_URL);
        const nodeStats = new ethers.Contract(
          NODESTATS_ADDRESS,
          NodeStatsABI,
          provider
        );
        setNodeStatsContract(nodeStats);

        const nodeReputation = new ethers.Contract(
          NODEREPUTATION_ADDRESS,
          NodeReputationABI,
          provider
        );
        setNodeReputationContract(nodeReputation);

        const nodePool = new ethers.Contract(
          NODEPOOL_ADDRESS,
          NodePoolABI,
          provider
        );
        setNodePoolContract(nodePool);
      } catch (error) {
        console.error("Initialization error:", error);
        setError(`Failed to initialize provider: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const fetchNodeStatsOnTabSwitch = async () => {
      if (!address || !lookupAddress) return;
      console.log(address, lookupAddress, !address || !lookupAddress);

      try {
        setLoading(true);
        setError("");

        const statsAddress = lookupAddress || address;
        if (!statsAddress || !nodeStatsContract) return;
        if (!isAddress(statsAddress))
          throw new Error("Invalid Ethereum address");

        const nodeHash = ethers.keccak256(statsAddress);
        const statsData = await nodeStatsContract.getActivity(nodeHash);

        if (statsData) {
          setNodeStats(statsData);
        } else {
          setNodeStats(null);
        }
      } catch (error) {
        console.error("Error fetching stats on tab switch:", error);
        setError(`Failed to fetch stats: ${error.message}`);
        setNodeStats(null);
      } finally {
        setLoading(false);
      }
    };

    fetchNodeStatsOnTabSwitch();
  }, [lookupAddress, address, nodeStatsContract]);

  // Function to open metric analytics page
  const openMetricAnalytics = async (stat) => {
    if (!["Correctness", "Ping", "Global Ping"].includes(stat.label)) {
      setLoading(true);
      const { allTimestamps, successTimestamps } = await fetchTimeStampData(
        stat.label.toLowerCase()
      );

      if (allTimestamps.length > 0) {
        searchParams.set("metric", stat.label);
        setSearchParams(searchParams);
      } else {
        setLoading(false);
        setShowPopup(true); // Show popup if metric does not contain any timestamp data
      }
    } else if (stat.label === "Ping") {
      setLoading(true);
      const pingTimestamps = await fetchTimeStampData(stat.label.toLowerCase());
      if (pingTimestamps.length > 0) {
        searchParams.set("metric", stat.label);
        setSearchParams(searchParams);
      } else {
        setLoading(false);
        setShowPopup(true); // Show popup if metric does not contain any timestamp
      }
    } else {
      setShowPopup(true); // Show popup if metrics is not supported
    }
  };

  const closePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const handleReset = (e) => {
    e.preventDefault();
    setQuery("");
    sessionStorage.removeItem("node");
    if (address) {
      // Navigate to connected wallet's stats if wallet is connected
      navigate(`/stats/node/${address}`);
    } else {
      // Navigate back to generic lookup page if no wallet is connected
      navigate("/stats/node");
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!lookupAddress) {
    return (
      <div className="empty-state">
        <div className="empty-state-content">
          <Activity className="icon" size={48} />
          <h3>No Wallet Connected</h3>
          <p>Please connect your wallet to view node statistics</p>
          <p>
            or use the lookup feature below to search for a specific address
          </p>
        </div>
        <div className="address-lookup">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter address to lookup"
            className="address-input"
          />

          <button onClick={handleAddressLookup} className="lookup-button">
            Lookup
          </button>
        </div>
        {error && (
          <div className="error-message">
            <p>{error}</p>
          </div>
        )}
      </div>
    );
  }

  if (metric) {
    return (
      <MetricsAnalytics
        allTimestamps={allTimestamps}
        successTimestamps={successTimestamps}
        pingTimestamps={pingTimestamps}
        pingThreshold={pingThreshold}
        error={error}
      />
    );
  }

  const statsAddress = lookupAddress || address;

  return (
    <div>
      <div
        className="refresh-section address-display"
        style={{ borderTopLeftRadius: "0px" }}
      >
        <div className="current-address">
          <p>
            Showing stats for:{" "}
            <a
              href={`https://sepolia.arbiscan.io/address/${statsAddress}`}
              target="_blank"
            >
              {statsAddress}
            </a>
          </p>
        </div>
        <div className="address-lookup">
          <div className="address-search">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                console.log(e.keyCode);
                if (e.keyCode === 13) handleAddressLookup();
              }}
              placeholder="Enter address to lookup"
              className="address-input"
            />
            <X
              onClick={handleReset}
              className="icon"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            className="pagination-buttons"
            style={{ flexWrap: "nowrap", marginTop: 0 }}
          >
            <a
              onClick={handleAddressLookup}
              className="pagination-btn btn link-button"
            >
              Look Up
            </a>
            {/* <a onClick={handleReset} className="pagination-btn btn link-button">
              Reset
            </a> */}
            <a
              href={`http://twitter.com/share?text=${encodeURIComponent(
                "#Cortensor Dashboard: Node Stats - "
              )}&url=${encodeURIComponent(window.location.href)}`}
              target="_blank"
              className="pagination-btn btn link-button share-btn"
            >
              <i className="fa-solid fa-share icon-spacing"></i>
              <span>Share</span>
            </a>
          </div>
        </div>
      </div>

      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}

      {!nodeStats || Object.keys(nodeStats).length === 0 ? (
        <p className="no-stats-message">
          No statistics available for this address
        </p>
      ) : (
        <div className="node-stats-container">
          {organizeStats(nodeStats).map((stat, idx) => (
            <div
              key={idx}
              onClick={() => openMetricAnalytics(stat)}
              onMouseLeave={() => {
                const tooltip = document.getElementById(`tooltip-${idx}`);
                if (tooltip) tooltip.style.visibility = "hidden"; // Manually hide tooltip
              }}
              className="node-stat-row"
              style={{ cursor: "pointer" }}
              data-tooltip-id={`tooltip-${idx}`}
              // data-tooltip-content={`View ${stat.label} Metrics`}
              data-tooltip-html={`<span style="display: flex; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="black">
                  <path d="M12 4.5C7.25 4.5 3.16 7.61 1 12c2.16 4.39 6.25 7.5 11 7.5s8.84-3.11 11-7.5c-2.16-4.39-6.25-7.5-11-7.5zm0 13c-3.04 0-5.5-2.46-5.5-5.5S8.96 6.5 12 6.5s5.5 2.46 5.5 5.5-2.46 5.5-5.5 5.5zm0-9C9.51 9 7.5 11.01 7.5 13.5S9.51 18 12 18s4.5-2.01 4.5-4.5S14.49 9 12 9zm0 7c-1.37 0-2.5-1.13-2.5-2.5S10.63 11 12 11s2.5 1.13 2.5 2.5S13.37 16 12 16z"/>
                </svg>
                &nbsp;&nbsp; Click to view ${stat.label} Metrics
              </span>`}
            >
              <h4>{stat.label} Metrics</h4>
              <p>
                <strong>Success:</strong> {stat.point}
              </p>
              <p>
                <strong>Attempt:</strong> {stat.counter}
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <strong>Success Rate:&nbsp;</strong>{" "}
                <span
                  style={{
                    color:
                      stat.successRate > 0
                        ? "#2fb135"
                        : stat.successRate < 0
                        ? "#d55555"
                        : undefined,
                  }}
                >
                  {stat.successRate ? stat.successRate.toFixed(2) + "%" : "N/A"}
                  {stat.successRate > 0 ? (
                    <ArrowUpRight
                      className="icon"
                      style={{ position: "relative", top: "3px" }}
                    />
                  ) : (
                    stat.successRate < 0 && (
                      <ArrowDownRight
                        className="icon"
                        style={{ position: "relative", top: "3px" }}
                      />
                    )
                  )}
                </span>
              </p>
              <Tooltip
                id={`tooltip-${idx}`}
                effect="solid"
                float={true} // Makes it follow the cursor
                offset={10} // Adjusts distance from cursor
                style={{
                  backgroundColor: "rgb(201, 196, 196)",
                  color: "black",
                  fontSize: "12px",
                  borderRadius: "5px",
                  padding: "6px 10px",
                }}
              />
            </div>
          ))}
        </div>
      )}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>This Metric does not contain any Timestamp Data.</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NodeStats;
