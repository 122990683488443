import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import {
  RefreshCw,
  Search,
  ChevronLast,
  ChevronRight,
  ChevronFirst,
  ChevronLeft,
  Link as LinkIcon,
} from "lucide-react";
import { Copy } from 'lucide-react';
import CognitiveABI from "../abi/Cognitive.json";
import Button from "../components/Button";
import { COGNITIVE_ADDRESS, PUBLIC_RPC_URL } from "../lib/config";
import { useParams, useNavigate, Link } from "react-router";
import Loader from "./Loader";
import { Tooltip } from "@mui/material";

const Cognitive = () => {
  const { isConnected } = useAccount();
  const [cognitiveContract, setCognitiveContract] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [cognitiveSessions, setCognitiveSessions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [latestSessionId, setLatestSessionId] = useState(0);
  const [lookupSession, setLookupSession] = useState("");
  const { sessionId: sessionIdString } = useParams();
  const sessionId = Number(sessionIdString);
  const navigate = useNavigate();

  const handleCopy = (id, idx) => {
    navigator.clipboard.writeText(id);
    setCopiedIndex(idx);
  };

  const resetCopyState = () => {
    setCopiedIndex(null); // Reset copied state when focus is lost
  };

  const changeSession = (id) => {
    navigate("/cognitive/" + id);
    sessionStorage.setItem("sessionId", id);
  };

  const handleSessionLookup = () => {
    changeSession(lookupSession);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSessionLookup();
    }
  };

  const getSessionStateText = (state) => {
    const states = {
      0: "Requested",
      1: "Created",
      2: "Prepared",
      3: "Active",
      4: "Precommitted",
      5: "Committed",
      6: "Ended",
    };
    return states[state] || "Unknown";
  };

  const fetchData = () => {
    if (!cognitiveContract || !latestSessionId) return;
    if (!sessionId) return changeSession(latestSessionId);
    setLoading(true);
    cognitiveContract
      .get(sessionId)
      .then(setCognitiveSessions)
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(`Failed to fetch data: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || timestamp === 0) return "N/A";
    return new Date(Number(timestamp) * 1000).toLocaleString();
  };

  useEffect(() => {
    const provider = new ethers.JsonRpcProvider(PUBLIC_RPC_URL);
    const cognitive = new ethers.Contract(
      COGNITIVE_ADDRESS,
      CognitiveABI,
      provider
    );
    setCognitiveContract(cognitive);
  }, [isConnected]);

  useEffect(() => {
    if (!cognitiveContract) return;
    cognitiveContract
      .getLatestSessionId()
      .then((latestId) => setLatestSessionId(Number(latestId)))
      .catch((error) => {
        console.error(error);
        setError(error.message);
      });
  }, [cognitiveContract]);

  useEffect(() => {
    if (sessionIdString === undefined) {
      // If no sessionId in the URL, navigate to the latest session
      if (latestSessionId > 0) {
        changeSession(latestSessionId);
      }
    } else {
      sessionStorage.setItem("sessionId", sessionIdString);
    }
  }, [sessionIdString]);

  useEffect(fetchData, [sessionId, cognitiveContract, latestSessionId]);
  if (loading) {
    return <Loader />;
  }
  if (!cognitiveSessions) return <p>No cognitive sessions available.</p>;

  return (
    <div>
      <div className="refresh-section">
        <div className="refresh-content">
          <div className="session-info">
            <p className="latest-session">
              Latest Session ID: <strong>{latestSessionId}</strong>
            </p>
            <div className="session-lookup">
              {/* as the session id will be an integer only so we can prevent the user by typing lettere */}
              <input
                type="number"
                value={lookupSession}
                onChange={(e) => setLookupSession(e.target.value)}
                placeholder="Enter session ID to lookup"
                className="session-input"
                onKeyDown={handleKeyDown}
              />
              {/* search icon will only be visible if there is some value */}
              {
                lookupSession && <>
                  <Search
                    onClick={handleSessionLookup}
                    className="icon"
                    style={{ cursor: "pointer" }}
                  />
                </>
              }
            </div>
            <div className="pagination-container">
              <Button
                onClick={() => changeSession(sessionId - 1)}
                disabled={sessionId === 1}
                className="pagination-btn-new"
              >
                <Tooltip title="Previous Session" style={{ display: "flex" }}>
                  <ChevronLeft className="icon-nav" />
                  <span style={{ marginRight: "10px" }}>Previous</span>
                </Tooltip>
              </Button>
              <Button
                onClick={() => changeSession(sessionId + 1)}
                disabled={sessionId >= latestSessionId}
                className="pagination-btn-new"
              >
                <Tooltip title="Next Session" style={{ display: "flex" }}>
                  <span>Next</span>
                  <ChevronRight className="icon-nav" />
                </Tooltip>
              </Button>
            </div>
          </div>
          <div className="pagination-buttons">
            <Button
              onClick={() => changeSession(1)}
              disabled={sessionId === 1}
              className="pagination-btn"
            >
              <Tooltip title="First Session" style={{ display: "flex" }}>
                <ChevronFirst className="icon-nav" />
                <span>First</span>
              </Tooltip>
            </Button>
            <Button
              onClick={() => changeSession(latestSessionId)}
              disabled={sessionId === latestSessionId}
              className="pagination-btn"
            >

              <Tooltip title="Latest Session" style={{ display: "flex" }}>
                <span>Latest</span>
                <ChevronLast className="icon-nav" />
              </Tooltip>
            </Button>
            <a
              onClick={() =>
                window.open(
                  `http://twitter.com/share?text=${encodeURIComponent(
                    "#Cortensor Dashboard: Cognitive - "
                  )}&url=${encodeURIComponent(window.location.href)}`
                )
              }
              className="btn pagination-btn link-button share-btn"
            >
              <i className="fa-solid fa-share icon-spacing"></i>
              <span>Share</span>
            </a>
            <Button onClick={fetchData} className="refresh-button">
              <RefreshCw className="icon" />
              Refresh
            </Button>
          </div>
        </div>
      </div>

      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      <div className="sessions-container">
        <div className="sessions-list">
          {cognitiveSessions && (
            <div className="session-item">
              <div className="session-header">
                <h4>Session #{cognitiveSessions[0].toString()}</h4>
                <p className="status-branding">
                  <strong>Status: </strong>
                  {getSessionStateText(Number(cognitiveSessions[8+1]))}
                </p>
              </div>
              <div className="two-column-grid">
                {/* Left Column */}
                <div className="column">
                  {/* Basic Info */}
                  <div className="info-group">
                    <h5>Session Details</h5>
                    <p>
                      <strong>ID:</strong> {cognitiveSessions[0].toString()}
                    </p>
                    <p>
                      <strong>Created:</strong>{" "}
                      {formatTimestamp(cognitiveSessions[1])}
                    </p>
                    <p>
                      <strong>Started:</strong>{" "}
                      {formatTimestamp(cognitiveSessions[9+1])}
                    </p>
                    <p>
                      <strong>Ended:</strong>{" "}
                      {formatTimestamp(cognitiveSessions[10+1])}
                    </p>
                  </div>
                  {/* Participants Information */}
                  <div className="info-group">
                    <h5>Participants</h5>
                    <p>
                      <strong>Creator:</strong>{" "}
                      <Link to={"/stats/node/" + cognitiveSessions[11+1]}>
                        <div className="link-container m-t-b-16">
                          <LinkIcon className="icon icon-spacing" />
                          {cognitiveSessions[11+1]}
                        </div>
                      </Link>
                    </p>
                    <div className="inner-card">
                      <strong>Preparers:</strong>
                      <span className="show-dash">
                        {cognitiveSessions[12+1].length === 0 && "—"}
                      </span>
                      <ul>
                        {cognitiveSessions[12+1] &&
                          cognitiveSessions[12+1].map((preparer, idx) => (
                            <p className="output-display" key={idx}>
                              <Link to={"/stats/node/" + preparer} key={idx}>
                                <div className="link-container">
                                  <LinkIcon className="icon icon-spacing" />
                                  <div>{preparer}</div>
                                </div>
                              </Link>
                            </p>
                          ))}
                      </ul>
                    </div>
                    <p>
                      <strong>Preparer Index:</strong>
                      {cognitiveSessions[13+1].toString()}
                    </p>
                    <div className="inner-card">
                      <strong>Miners:</strong>
                      <span className="show-dash">
                        {cognitiveSessions[7+1].length === 0 && "—"}
                      </span>
                      <ul>
                        {cognitiveSessions[7+1] &&
                          cognitiveSessions[7+1].map((miner, idx) => (
                            <p className="output-display" key={idx}>
                              <Link to={"/stats/node/" + miner} key={idx}>
                                <div className="link-container">
                                  <LinkIcon className="icon icon-spacing" />

                                  <div>{miner}</div>
                                </div>
                              </Link>
                            </p>
                          ))}
                      </ul>
                    </div>
                    <div className="inner-card">
                      <strong>Validators:</strong>
                      <span className="show-dash">
                        {cognitiveSessions[18+1].length === 0 && "—"}
                      </span>{" "}
                      <ul>
                        {cognitiveSessions[18+1] &&
                          cognitiveSessions[18+1].map((validator, idx) => (
                            <p>
                              <li key={idx}>{validator}</li>
                            </p>
                          ))}
                      </ul>
                    </div>
                  </div>
                  {/* Submission Details */}
                  <div className="info-group">
                    <h5>Submission Details</h5>
                    <p>
                      <strong>Completion Counter:</strong>
                      {cognitiveSessions[17+1].toString()}
                    </p>
                    <div className="inner-card">
                      <strong>Submitter IDs:</strong>
                      <span className="show-dash">
                        {cognitiveSessions[14+1].length === 0 && "—"}
                      </span>
                      <ul>
                        {cognitiveSessions[14+1] &&
                          cognitiveSessions[14+1].map((id, idx) => (
                            <li key={idx}>
                              <div className="submitter-container">
                                <Tooltip
                                  title={id}
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -16], // Adjust the vertical distance here
                                        },
                                      },
                                    ],
                                  }}
                                >
                                  <div className="submitter-container">
                                    <i className="fa-solid fa-circle icon-spacing small-icon"></i>
                                    <div className="submitter-id">{id}</div>
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    copiedIndex === idx ? "Copied!" : "Copy"
                                  }
                                  // sx={{ zIndex: -1 }}
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -8], // Adjust the vertical distance here
                                        },
                                      },
                                    ],
                                  }}
                                >
                                  <Copy
                                    onClick={() => handleCopy(id, idx)}
                                    onMouseLeave={resetCopyState} // Reset when the mouse leaves
                                    onBlur={resetCopyState}
                                    className="copy-btn"
                                  />
                                </Tooltip>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Right Column */}
                <div className="column">
                  {/* Task Information */}
                  <div className="info-group">
                    <h5>Task Information</h5>
                    <p>
                      <strong>Domain:</strong> {cognitiveSessions[2+1]}
                    </p>
                    <p>
                      <strong>Subdomain:</strong> {cognitiveSessions[3+1]}
                    </p>
                    <p>
                      <strong>Max Prompts:</strong>{" "}
                      {cognitiveSessions[4+1].toString()}
                    </p>
                    <p>
                      <strong>Challenge Index:</strong>{" "}
                      {cognitiveSessions[5+1].toString()}
                    </p>
                    <p>
                      <strong>Challenge Number:</strong>{" "}
                      {(parseInt(cognitiveSessions[5+1]) + 1).toString()}
                    </p>
                    <div className="challenge-list inner-card">
                      <strong>Challenges:</strong>
                      <span className="show-dash">
                        {cognitiveSessions[6+1].length === 0 && "—"}
                      </span>
                      <ul>
                        {cognitiveSessions[6+1] &&
                          cognitiveSessions[6+1].map((challenge, idx) => (
                            <li key={idx}>{challenge}</li>
                          ))}
                      </ul>
                    </div>
                    <div className="completions-list inner-card">
                      <strong>Outputs:</strong>
                      <span className="show-dash">
                        {cognitiveSessions[16+1].length === 0 && "—"}
                      </span>
                      <ul>
                        {cognitiveSessions[16+1] &&
                          cognitiveSessions[16+1].map((completion, idx) => (
                            <li key={idx}>
                              <strong>Output {idx + 1}:</strong>
                              <p className="output-display">{completion}</p>
                              <small>
                                Hash:{" "}
                                <span className="italic-font">
                                  {cognitiveSessions[15+1] &&
                                    cognitiveSessions[15+1][idx]}
                                </span>
                              </small>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cognitive;
