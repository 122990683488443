import React, { useState, useEffect } from "react";
import { ArrowUpRight, ArrowDownRight } from "lucide-react";

import Loader from "./Loader";

const NetworkStats = () => {
  const [loading, setLoading] = useState(true);
  const [networkStats, setNetworkStats] = useState(null);

  useEffect(() => {
    setLoading(true);

    fetch("https://lb-be-3.cortensor.network/leaderboard")
      .then((res) => res.json())
      .then((data) => {
        const networkStats = processNetworkStatsData(data);
        setNetworkStats(networkStats);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const processNetworkStatsData = (nodes) => {
    let requestCounter = 0;
    let createCounter = 0;
    let prepareCounter = 0;
    let precommitCounter = 0;
    let commitCounter = 0;
    let requestPoint = 0;
    let createPoint = 0;
    let preparePoint = 0;
    let precommitPoint = 0;
    let commitPoint = 0;

    nodes.forEach((node) => {
      requestCounter += node.requestCounter || 0;
      createCounter += node.createCounter || 0;
      prepareCounter += node.prepareCounter || 0;
      precommitCounter += node.precommitCounter || 0;
      commitCounter += node.commitCounter || 0;
      requestPoint += node.requestPoint || 0;
      createPoint += node.createPoint || 0;
      preparePoint += node.preparePoint || 0;
      precommitPoint += node.precommitPoint || 0;
      commitPoint += node.commitPoint || 0;
    });

    const totalCount =
      requestCounter +
      createCounter +
      prepareCounter +
      precommitCounter +
      commitCounter;

    const totalPoint =
      requestPoint + createPoint + preparePoint + precommitPoint + commitPoint;

    return {
      requestCounter,
      createCounter,
      prepareCounter,
      precommitCounter,
      commitCounter,
      requestPoint,
      createPoint,
      preparePoint,
      precommitPoint,
      commitPoint,
      totalCount,
      totalPoint,
    };
  };

  const organizeStats = (networkStats) => {
    const formatDisplayLabel = (prefix) => {
      return prefix.charAt(0).toUpperCase() + prefix.slice(1);
    };

    const prefixes = ["Request", "Create", "Prepare", "Precommit", "Commit"];
    const organizedStats = prefixes.map((prefix) => {
      const getPropertyKey = (prefix, suffix) => {
        return `${prefix.toLowerCase()}${suffix}`;
      };

      const pointKey = getPropertyKey(prefix, "Point");
      const counterKey = getPropertyKey(prefix, "Counter");

      const pointValue = networkStats[pointKey];
      const counterValue = networkStats[counterKey];

      return {
        label: formatDisplayLabel(prefix),
        point: pointValue ? pointValue.toString() : "0",
        counter: counterValue ? counterValue.toString() : "0",
        successRate: calculateSuccessRate(pointValue, counterValue),
      };
    });
    organizedStats.push({
      label: "Total (Exclude Ping)",
      point: (networkStats.totalPoint || 0).toString(),
      counter: (networkStats.totalCount || 0).toString(),
      successRate: calculateSuccessRate(
        networkStats.totalPoint,
        networkStats.totalCount
      ),
    });
    return organizedStats;
  };
  const calculateSuccessRate = (point, counter) => {
    let numericPoint = typeof point === "bigint" ? Number(point) : point;
    let numericCounter =
      typeof counter === "bigint" ? Number(counter) : counter;

    if (
      numericCounter === 0 ||
      numericCounter === "0" ||
      isNaN(numericPoint / numericCounter)
    )
      return null;
    return (numericPoint / numericCounter) * 100;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="node-stats-container">
      {organizeStats(networkStats).map((stat, idx) => (
        <div key={idx} className="node-stat-row">
          <h4>{stat.label} Metrics</h4>
          <p>
            <strong>Success:</strong> {stat.point}
          </p>
          <p>
            <strong>Attempt:</strong> {stat.counter}
          </p>
          <p style={{ display: "flex", alignItems: "center" }}>
            <strong>Success Rate:&nbsp;</strong>{" "}
            <span
              style={{
                color:
                  stat.successRate > 0
                    ? "#2fb135"
                    : stat.successRate < 0
                    ? "#d55555"
                    : undefined,
              }}
            >
              {stat.successRate ? stat.successRate.toFixed(2) + "%" : "N/A"}
              {stat.successRate > 0 ? (
                <ArrowUpRight
                  className="icon"
                  style={{ position: "relative", top: "3px" }}
                />
              ) : (
                stat.successRate < 0 && (
                  <ArrowDownRight
                    className="icon"
                    style={{ position: "relative", top: "3px" }}
                  />
                )
              )}
            </span>
          </p>
        </div>
      ))}
    </div>
  );
};

export default NetworkStats;
