import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider, http } from "wagmi";
import { arbitrumSepolia, mainnet } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router";

import "./App.css";

import Layout from "./pages/Layout";
import Cognitive from "./pages/Cognitive";
import NodeStats from "./pages/NodeStats";
import Node from "./pages/Node";
import NetworkStats from "./pages/NetworkStats";
import Stats from "./pages/Stats";

const config = getDefaultConfig({
  appName: "Cortensor Dashboard",
  projectId:
    process.env.REACT_APP_PROJECT_ID || "1d7c4617eef70e9a0c3f5db0c9c9d5c4",
  chains: [mainnet, arbitrumSepolia],
  ssr: true,
  //transports: {
  //  [arbitrumSepolia.id]: http('https://arb-sepolia.g.alchemy.com/v2/PxmPZjSzDQFS69TyRjomTk7JVU-tmC4P')
  //},
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="cognitive" />} />
                <Route path="/cognitive/:sessionId?" element={<Cognitive />} />
                <Route path="/stats" element={<Stats />}>
                  <Route path="node/:lookupAddress?" element={<NodeStats />} />
                  <Route path="network" element={<NetworkStats />} />
                </Route>

                <Route path="/node" element={<Node />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
